.about {
    grid-column: leftgutter-start / rightgutter-end;

    display: grid;
    grid-template-columns: [leftgutter-start] minmax(3.6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.6rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;

    padding-top: 10rem;
    padding-bottom: 19.5rem;

    background-image: url(../../assets/img/bg2.png), url(../../assets/img/bg3.png), url(../../assets/img/bg2.png);
    background-position: 105% -8%, -1% 41%, -8% 92%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 700px) {
        background-position: 205% -23%, -1% 24%, -8% 92%;

        }

    &__team {
        grid-column: leftgutter-start / rightgutter-end;
        display: grid;
        grid-template-columns: [leftgutter-start] minmax(3.6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.6rem,1fr) [rightgutter-end];
        grid-gap: 1.6;
        margin-top: 5rem;
        &-text {
            grid-column: full-start / col-end 3;

            @media only screen and (max-width: 600px) {
                grid-column: full-start / full-end ;

    
             }

        }

    }

    &__teammates {
        grid-column: col-start 4 / full-end;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1.6rem;
        row-gap: 2rem;

        @media only screen and (max-width: 1065px) {
            grid-template-columns: 1fr 1fr ;

         }

         @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr;
            grid-column: full-start / full-end;
            margin-top: 4rem;

         }
    }
}