.navigation {
    grid-column: full-start / full-end;


    &__logoBox {
        @media only screen and (max-width: 450px) {
            width: 70%;

         }
    }

    &__logoImg {
        @media only screen and (max-width: 450px) {
            width: 100%;

         }
    }
  
    &__info {

        display: flex;
        justify-content: space-between;
        align-items: center;

        &-container {
            display: flex;
            column-gap: 10.6rem;
            margin-right: 5.5rem;

            @media only screen and (max-width: 690px) {
                display: none;
    
             }
        }

        &-content {
            display: flex;
    
            align-items: center;
            column-gap: 2.5rem;
        }
    
        &-icon {
            height: 1.9rem;
            width: 1.9rem;
            object-fit: contain;
        }

    }

    &__links {
        width: 100%;
        height: 5.6rem;

        border-top: 1px solid #BBBACA;
        display: flex;
        justify-content: flex-start;
       
        @media only screen and (max-width: 690px) {
            position: relative;
            z-index: 1001;
            flex-direction: column;
            border-top: 0;
            height: 100% !important;
            display: none;
            animation-name: moveInLeft;
            animation-timing-function: ease-in-out;
            animation-duration: .7s;
            position: fixed;
            height: 100vh;
            width: 100vw;
            overflow: hidden;

        }
       
         &-container {
            margin-top: 2rem;
            // height: max-content !important;
            display: flex;

            @media only screen and (max-width: 690px) {
                flex-direction: column;
                row-gap: 3.5rem;
                
              }
        }

    }

    &__link {
        &,
        &:link,
        &:visited {
            font-family: 'circeLight';
            font-size: 1.3rem;
            line-height: 2.4rem;
            color: #82809D;
            text-decoration: none;
            text-transform: uppercase;
            background-color: transparent;
            cursor: pointer;
            border-right: .25px solid #BBBACA;

            padding-right: 4.35rem;
            height: fit-content;
            width: max-content;
           display: inline-block;

           @media only screen and (max-width: 690px) {
                border: none;
                font-family: 'circe';
                font-size: 4.5rem;
                height: max-content;
                color: #fff;

          }
      
        }
      
        &:active,
        &:focus {
            outline: none;
        }
        &:last-child {
            border-right: none;
        }

        &:not(:first-child) {
            padding-left: 3.15rem !important;

            @media only screen and (max-width: 690px) {
                padding-left: 0!important;


          }
        }

        &:first-child {
            padding-left: 1.5rem !important;

            @media only screen and (max-width: 690px) {
                padding-left: 0!important;


          }
        }

       &-active {
            font-family: 'circeBold' !important;
            color: #24232E !important;
            position: relative !important;

            @media only screen and (max-width: 690px) {
                 color: #fff !important;

               }

            &::after {
                content: "";
                height: 3px;
                width: 70%;
                background-color: #EB3F57;
                position: absolute;
                top: -2.4rem;
                left: 0;

                @media only screen and (max-width: 690px) {
                   top: 3.4rem !important;
                    background-color: #fff;

                  }
            }

       }
    }

    &__searchBox {
        display: flex;
        width: 33%;
        @media only screen and (max-width: 690px) {
            display: none;
            

      }
    }

    &__searchInput {
        font-family: 'circeExtraLight';
        font-size: 1.4rem;
        line-height: 2.3rem;
        color: #82809D;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 2rem 2.8rem;
        padding-top: .7rem !important;
        padding-bottom: 0 !important;
        border-left: .25px solid #BBBACA;
        width: 100%;

    }

    &__searchBtn {
        padding-top: .7rem !important;

        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
    }

    &__checkbox {
        display: none;

    }

    &__button {
        background-color: #fff;
        height: 7rem;
        width: 7rem;
        position: fixed;
        top: 4rem;
        right: 6rem;
        border-radius: 50%;
        z-index: 2000;
        box-shadow: 0 1rem 4rem rgba(#000, .1);
        text-align: center;
        cursor: pointer;

        display: none;

        @media only screen and (max-width: 690px) {
            display: block;

         }

        // @include respond(tab-port) {
        //     top: 4rem;
        //     right: 4rem;
        // }
        
        // @include respond(mobile) {
        //     top: 3.5rem;
        //     right: 3.5rem; 
        // }
    }
        //Icon
        &__icon {
            position: relative;
            margin-top: 3.5rem;
            transition: all .2s;
    
            &,
            &::before,
            &::after {
                width: 3rem;
                height: 2px;
                background-color: var(--color-primary);
                display: inline-block;
            }
    
            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                transition: all .2s ;
                
}
    
            &::before { top: -.8rem; }
    
            &::after { top: .8rem; }
    
    
        }


        &__background {
            height: 6rem;
            width: 6rem;
            border-radius: 50%;
            position: fixed;
            top: 4.5rem;
            right: 6.5rem;
            background-image: radial-gradient(var(--color-primary), var(--color-primary-dark));
            z-index: 1000;
            transition: transform 0.8s cubic-bezier(0.86,0,0.07,1);
            display: none;

            @media only screen and (max-width: 690px) {
                display: block;
      
               }
        }
           //Functionality
    &__checkbox:checked ~ &__background {
        transform: scale(80);
    }

    &__checkbox:checked ~ &__links {

        @media only screen and (max-width: 690px) {
          display: flex;

         }
       

    }
   
}