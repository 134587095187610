.teammate {
    padding: 3.6rem 5.8rem;
    background-color: #FEFEFE;
    border: 1px solid #ECECEC;
    height: max-content;
    width: 100%;
    text-align: center;
    transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
    -ms-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    
    &:hover {
        box-shadow: 1px 3px 10px rgba($color: #000000, $alpha: .10);
        border: 1px solid transparent;
    }


    &__img {
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        object-fit: cover;
        height: 12.7rem;
        width: 12.7rem;
        object-position: top;
    }

    &__social {
        display: flex;
        column-gap: 3.8rem;
        align-items: center;
        width: 100%;
        justify-content: center;
        & svg {
            fill: #ececec;
            cursor: pointer;        
            transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            -moz-transition: all .2s ease-in;
            -ms-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;

            &:hover {
            fill: #b1b1b1;

            }
        }
    }
}