.breadcrumbs {
    grid-column: leftgutter-start / rightgutter-end;
    background-image: url(../../assets/img/Breadcrumbs.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 15.5rem;
    margin-top: 2rem;
    padding: 6.9rem 13.9rem;

    &__links {
        display: flex;
    }
}