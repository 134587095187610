.aboutDynDesc {
    grid-column: full-start / full-end;
    // padding-top: 10rem;
    display: flex;
    column-gap: 3.7rem;

    margin-bottom: 13.55rem;

    @media only screen and (max-width: 600px) {
        flex-direction: column-reverse;
     }

    &__tabs {
        height: max-content;

        display: flex;
        flex-direction: column;

        padding: 2.7rem 2rem 2.7rem 0;
        box-shadow: 2px 6px 15px rgba(#D1D1D1, .3);

        @media only screen and (max-width: 700px) {
            display: none;
         }

         @media only screen and (max-width: 600px) {
            display: block;
         }
    }

    
    &__textContent {
        @media only screen and (max-width: 600px) {
            display: none;
         }
    }

    &__btn {
        border: none;
        background-color: transparent;
        font-family: 'circeExtraLight';
        font-size: 1.5rem;
        line-height: 2.4rem;
        color: var(--color-gray);
        outline: none;
        border-left: .3rem solid transparent;
        padding-left: 1.5rem;
        cursor: pointer;

        transition: font-family .3s ease-in;
        
        &:hover {
            font-family: 'circeBold';

        }

        &-active {
            font-family: 'circeBold';
            border-left: .3rem solid var(--color-primary);
        }

        &-container {
            width: 23.9rem;

        }

        &-wrapper:not(:last-child) {
            margin-bottom: 2rem;

        }
    }

    &__content {

        &-wrapper {
            display: flex;
            column-gap: 6.8rem;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 600px) {
                flex-direction: column;
             }
        }

        &-picture {
            width: 210%;
            @media only screen and (max-width: 600px) {
                height: 21rem;
                margin-top: 3.5rem;
                margin-bottom: 3.5rem;
                width: 117.52%;
             }
        }

        &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
}

