.footerContact {
    grid-column: leftgutter-start / rightgutter-end;
    position: relative;
    height: 81rem;
    margin-bottom: 19.5rem;

    @media only screen and (max-width: 1152px) {
        height: max-content;
        display: none;
     }
    // width: 100%;
    &::before {
        content: "";
        height: 68.2rem;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../../assets/img/pic1.png);
        background-size: cover;

        @media only screen and (max-width: 1252px) {
            height:72rem

     }
    }

    &::before {
        content: "";
        height: 68.2rem;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../../assets/img/pic1.png);
        background-size: cover;

        @media only screen and (max-width: 1252px) {
            height:72rem

     }
    }

    &::after {
        content: "";
        height: 68.2rem;
        width: 100%;
        background-color: rgba(#EB3F57, .90);
        position: absolute;
        top: 0;
        right: 0;

        @media only screen and (max-width: 1252px) {
            height:72rem

     }
    }

    &__container {
        display: flex;
        position: absolute;
        top: 8.6rem;
        left: 13.8rem;
        z-index: 3;
        column-gap: 5rem;

        @media only screen and (max-width: 1152px) {
            flex-direction: column-reverse;
            height: max-content
    
         }
    }

    &__form {
        display: flex;
        flex-direction: column;

        &-wrapper {
            padding: 5.2rem 5.7rem 5rem 4.8rem;
            background-color: #FFF;
            box-shadow: 0 3px 10px rgba(#24232E, .25);
            width: 40.6rem;
        }

        & label {
            font-family: 'circeExtraLight';
            font-size: 1.2rem;
            line-height: 2.4rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 1.2rem;
        }

        & input[type=text], input[type=email] {
            border: none;
            outline: none;
            border-bottom: 1px solid #ECECEC;
            padding-bottom: .9rem;

            font-family: 'circe';
            font-size: 1.2rem;
            line-height: 2.4rem;
            margin-bottom: 2rem;
            background-color: #fff;

            &:focus {
                outline: none;
                background-color: transparent;
                // box-shadow: 0 1rem 2rem rgba(var(--color-black), .1);
                // border: 1px solid var(--color-primary);
            }
        }
    }

    &__btnWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 5rem;
    }

    &__call {
        font-family: 'circeExtraLight';
        font-size: 1rem;
        line-height: 1.4rem;
        color: #777777;
    }

    &__wrapper {
        position: relative;
    }

    &__clientele {
        // width: 35.2rem;
        position: relative;
        width: 100%;
        // padding-right: 3.2rem;
        &-wrapper {
            display: flex;
        }

        &-writer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            margin-top: 2rem;
        }

        &-quote {
            font-family: 'engravers';
            font-size: 10rem;
            line-height: 11.7rem;
            color: #FFF;
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            height: max-content;
            width: max-content;
            text-align:top ;
            margin-right: .7rem;
            margin-top: -6rem;
            margin-left: -1rem;
        }

        &-content {
            padding: 3.5rem 3.5rem 2.3rem 3.5rem;
            background-color: rgba($color: #F4F3F6, $alpha: .1);
            // width: 352px;

        }

        &-container {
            // display: flex;
            // column-gap: 3rem;
            // overflow: hidden;
        }

        &-dots {
            margin-top: 3.75rem;
            margin-bottom: 3.35rem;
            display: flex;
            justify-content: center;
        }

        &-logos {
            display: flex;
            justify-content: center;
        }
    }

    &__newsletter {
        display: flex;

        position: absolute;
        bottom: 2.6rem;
        justify-content: center;
        align-items: center;

        & input[type=email] {
            border: none;
            outline: none;
            border-bottom: 1px solid #ECECEC;
            padding-bottom: .9rem;
            width: 24rem;
            font-family: 'circe';
            font-size: 1.2rem;
            line-height: 2.4rem;
            margin-bottom: 2rem;
            background-color: #fff;

            &:focus {
                outline: none;
                background-color: transparent;
                // box-shadow: 0 1rem 2rem rgba(var(--color-black), .1);
                // border: 1px solid var(--color-primary);
            }
        }
    }
}

.swiper-container {
    overflow: hidden !important;
}

.footerContact__clientele-container .swiper-container {
    max-width: 76.5rem;
    height: max-content !important;
    margin-bottom: 3.5rem;
    margin-left: 0 !important;

    @media only screen and (max-width: 1446px) {
        max-width: 66.5rem;

     }

      @media only screen and (max-width: 1252px) {
        max-width: 60.3rem;

     }

     @media only screen and (max-width: 1200px) {
        max-width: 55.3rem;

     }

     @media only screen and (max-width: 1152px) {
        width: 300rem;

     }

  }

  .footerContact__clientele-container .swiper-container .swiper-wrapper {
    height: max-content !important;
    padding-bottom: 6.75rem;
  }

  .clientele-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0 !important;
      column-gap: .6rem;
  }

  .clientele-pagination .swiper-pagination-bullet{
    font-size: 0;
    width: 5px !important;
    height: 5px !important;
    opacity: 1 !important;
    border-radius: 50% !important;
    
    background-color: #fff !important;
       

}

.clientele-pagination .swiper-pagination-bullet-active {
    height: 11px !important;
      width: 11px !important;
      border: 3px solid #fff !important;
    background-color: transparent !important;
}
  

.footerContact__clientele-logos  .swiper-container {
    max-width: 85.3rem;
    height: max-content !important;

    @media only screen and (max-width: 1446px) {
        max-width: 75.3rem;

     }

     @media only screen and (max-width: 1340px) {
        max-width: 65.3rem;

     }

      @media only screen and (max-width: 1252px) {
        max-width: 60.3rem;

     }

     @media only screen and (max-width: 1200px) {
        max-width: 55.3rem;

     }

     @media only screen and (max-width: 1152px) {
        max-width: 50.3rem;

     }

}

.footerContact__clientele-logos .swiper-container .swiper-wrapper {
    height: max-content !important;
  }
