.services {
    grid-column: full-start / full-end;
    margin-top: 19.5rem;
    margin-bottom: 19.5rem;

    &__container {
        display: grid;
        grid-template-columns: 1fr 4fr;
        column-gap: 3rem;

        @media only screen and (max-width: 690px) {
            grid-template-columns: 1fr;
            row-gap: 3rem;

         }
    }

    &__desc {
        height: 100%;

        &-wrapper {
            margin-bottom: 12rem;
            @media only screen and (max-width: 690px) {
                margin-bottom: 5.2rem;

    
    
             }
            
        }
    }

  

   

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @media only screen and (max-width: 520px) {
            grid-template-columns: 1fr 1fr;


         }

        &-box {
            position: relative;
            height: 26rem;
            // width: 27.5rem;
            border: 2px solid #F5F5F5;
            padding: 4.5rem 2.6rem 4.5rem  6rem;
            background-color: #FFFFFF;
            transition: all .2s ease-in;
            cursor: pointer;

            & svg {
                margin-bottom: 1.5rem;
                fill: var(--color-primary);
                transition: all .2s ease-in;

            }

            &:hover {
                background-color: var(--color-primary);
                box-shadow: 1px 3px 10px rgba(#C04545, .46);
                border: 2px solid transparent;

                & p {
                    color: #FFFFFF;
                }

                & svg {
                    fill: #FFFFFF;
                }
            }
        }
    }

    
   

    &-label {
        font-family: 'circeLight';
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--color-primary);
        transition: all .2s ease-in;

    }

    &-num {
        font-family: 'circeLight';
        font-size: 1rem;
        line-height: 1.4rem;
        color: var(--color-primary);
        transition: all .2s ease-in;

        position: absolute;
        right: 2.6rem;
        top: 4.5rem;
    }
}

