.footer {
    grid-column: leftgutter-start / rightgutter-end;
    margin-top: 2rem;

    background-color: #464554;

    display: grid;
    // grid-template-rows: 100vh repeat(7, min-content);
    grid-template-columns: [leftgutter-start] minmax(3.6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.6rem,1fr) [rightgutter-end];
    grid-gap: 1.6;

    &__container {
        grid-column: full-start / full-end;
    }

    &__logos {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: $bp-tiny) {
            flex-direction: column;
         }
    }

    &__social {
        display: flex;
        column-gap: 3.83rem;
        align-items: center;

        @media only screen and (max-width: $bp-tiny) {
            display: none;
         }

        &-icon {
            // width: ;
            // height: 100%;
            object-fit: content;
        }

        &-2 {
            display: none;

            @media only screen and (max-width: $bp-tiny) {
                display: flex;
                column-gap: 3.83rem;
                align-items: center;
                justify-content: center;
             }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3.5rem;

        @media only screen and (max-width: $bp-medium) {
            column-gap: 3rem;
         }

         @media only screen and (max-width: $bp-tiny) {
            column-gap: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 7.7rem;
         }
    }

    &__contact {
        @media only screen and (max-width: $bp-tiny) {
            display: none;
         }
    }

    &__divider {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3.26rem;
        &-logo {
            margin-left: 6.7rem;
            margin-right: 6.7rem;
        }

        & hr {
            width: 100%;
        }
    }

    &-text {
        font-family: 'circeLight';
        font-size: 1.1rem;
        line-height: 2.4rem;
        color: #FFF;
    }

    &__copy {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 5.4rem;
    }
}