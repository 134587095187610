.header {
    grid-column: leftgutter-start / rightgutter-end;
    
    display: grid;
    grid-template-columns: [leftgutter-start] minmax(3.6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.6rem,1fr) [rightgutter-end];
    grid-gap: 1.6;

    position: relative;

    height: 100vh;
    width: 100%;

    &__container {
        grid-column: full-start / full-end;
        margin-left: 11rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: max-content;

        @media only screen and (max-width: 850px) {
            margin-left: 0;
         }

         @media only screen and (max-width: 700px) {
            flex-direction: column;
            align-items: flex-start;
         }
    }

    &__wrapper {
        grid-column: full-start / full-end;
        height: max-content;
        overflow: hidden;
    }

    &__text {

        &-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            & hr {
                margin-right: 1.6rem;
                width: 20%;
                margin-top: 1rem;
                background-color: #ECECEC;
                height: 1px;
                border: none;
            }

           
        }

        &-wrapper2 {
            width: 50%;

            @media only screen and (max-width: 1166px) {
                width: 80%;

             }

             @media only screen and (max-width: 1024px) {
                width: 100%;

             }

            & p {
                width: 100%;
                margin-bottom: 3rem;
            }
        }
    }

    &__imgBox {

        @media only screen and (max-width: 800px) {
            width: 400%;
            height: 100%;
         }

         @media only screen and (max-width: 700px) {
            width: 110%;
            height: 100%;
            margin-top: -25rem;
         }

         @media only screen and (max-width: 450px) {
            width: 130%;
            height: 100%;
            margin-top: -25rem;
         }
         
    }

    &__paragraph {
        @media only screen and (max-width: 700px) {
            display: none;

         }
    }

    &__img {
        @media only screen and (max-width: 800px) {
            width: 100%;
            margin-top: 9rem;

         }
    }

    &__cta {
        display: inline-block;
    }

    &__pagination {
        display: flex;
        flex-direction: column;
        top: 8.2rem;
        left: 2rem;
        position: absolute;

        &-num {
            font-family: 'circeBold';
            font-size: 2rem;
            line-height: 6.4rem;
            color: #ECECEC;
            text-align: right;
            position: relative;
            &::after {
                content: "";
                background-color: #EB3F57;
                height: 7px;
                width: 7px;
                position: absolute;
                top: 27px;
                left: 3px;
            }





            &--active {
                font-family: 'circeBold';
                font-size: 4.6rem;
                line-height: 6.2rem;
                color: #24232E;
                
                border-bottom: 1px solid #707070;
                position: relative;
                &::after {
                    content: "";
                    background-color: #EB3F57;
                    height: 7px;
                    width: 7.2rem;
                    position: absolute;
                    bottom: 2.8rem;
                    left: -2.5rem;
                    z-index: -1;
                }
            }
        }
    }

}

// .swiper-pagination {
// 	position: absolute !important;
// 	top: 40% !important;
//     transform: translate(0, -50%);

// 	right: 0 !important;
// 	width: auto !important;
// 	left: auto !important;
// 	margin: 0;
//     // display: flex;
//     // flex-direction: column;
//     text-align: center !important;
//     align-items: center;
// }

.swiper-wrapper {
    height: 536px !important;
}



// .swiper-pagination-bullet {
//     width: 5px !important;
//     height: 5px !important;
//     text-align: center !important;
//     line-height: 20px !important;
//     font-size: 12px !important;
//     opacity: 1 !important;
//     background-color: #515067 !important;

//     &:not(:last-child){
//         margin-bottom: 1.4rem !important;
//     }
//   }

//   .swiper-pagination-bullet-active {
//       height: 10px !important;
//       width: 10px !important;
//       border: 3px solid #515067 !important;
//     background-color: transparent !important;
//   }

//   .swiper-pagination-bullet {
//       margin: auto !important;
//   }

.pagination-num {
    display: flex;
    flex-direction: column;
    top: 19.2rem !important;
    left: 2rem;
    position: absolute;

    @media only screen and (max-width: 850px) {
        display: none !important;


     }
}

.pagination-num .swiper-pagination-bullet{
    font-family: 'circeBold';
    font-size: 2rem;
    line-height: 6.4rem;
    color: #ECECEC;
    text-align: right;
    width: auto;
    height: auto;
    position: relative;
    width: max-content;
    background-color: transparent !important;
    padding-left: 2rem !important;
    opacity: 1 !important;

    &::after {
        content: "";
        background-color: #EB3F57;
        height: 7px;
        width: 7px;
        position: absolute;
        top: 27px;
        left: 3px;
    }
    }

.pagination-num .swiper-pagination-bullet-active {
    font-family: 'circeBold';
    font-size: 4.6rem;
    line-height: 6.2rem;
    color: #24232E;
    // order: -1;
    position: relative;
    padding-left: 0 !important;

    &::after {
        content: "";
        background-color: #EB3F57;
        height: 7px;
        width: 7.2rem;
        position: absolute;
        bottom: 2.8rem;
        left: -2.5rem;
        z-index: -1;
    }
}

.pagination-bull {
    display: flex;
    flex-direction: column;
    // text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
	top: 45% !important;
    transform: translate(0, -50%);

    @media only screen and (max-width: 850px) {
        display: none !important;


     }

}

.pagination-bull .swiper-pagination-bullet{
    font-size: 0;
    width: 5px !important;
    height: 5px !important;
    opacity: 1 !important;
    border-radius: 50% !important;
    
    background-color: #515067 !important;
        &:not(:last-child){
        margin-bottom: 1.4rem !important;
    }

}

.pagination-bull .swiper-pagination-bullet-active {
    height: 10px !important;
      width: 10px !important;
      border: 3px solid #515067 !important;
    background-color: transparent !important;
}