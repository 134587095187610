:root {
    --color-black: #24232E;
    --color-gray: #82809D;
    --color-gray-dark: #515067;
    

    --color-primary-light: #F5A3AE;
    --color-primary: #EB3F57;
    --color-primary-dark: #B91329;


    
}