.gallery {
    grid-column: full-start / rightgutter-end;
    margin-bottom: 15.5rem;
    position: relative;
    overflow: hidden;
    height: 54rem;
    width: 100%;
    // background-color: var(--color-primary);

    @media only screen and (max-width: 600px) {
        grid-column: full-start / full-end;
        height: 80rem;

        }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        animation-name: fadeIn;
        animation-duration: .3s;
        animation-timing-function: ease-in;
    }

    &::before {
        content: "";
        height: 100%;
        width: 35%;
        background-color: rgba(#EB3F57, .85);
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 3px 0px 10px rgba(#EB3F57, .35);

        @media only screen and (max-width: 1150px) {
            width: 50%;
  
           }

           @media only screen and (max-width: 600px) {
            width: 100%;
            top: 30rem;
    
    
            }

    }

    &::after {
        content: "";
        height: 100%;
        width: 100%;
        background-color: rgba(#C04545, .15);
        position: absolute;
        top: 0;
        right: 0;
    }

    &__container {
        position: absolute;
        top: 8.7rem;
        left: 7.2rem;
        width: 100%;

        z-index: 3;

        @media only screen and (max-width: 600px) {
            top: 35rem;
            }

    }

    // &__btnWrapper {

    // }

    &__btn {
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;

        width: 35%;
        margin-bottom: 1.5rem;

        @media only screen and (max-width: 600px) {
            width: 100%;

            }

            @media only screen and (max-width: 400px) {
                width: 80%;
        
                }
        & span {
            font-family: 'circeExtraLight';
            font-size: 1.2rem;
            line-height: 1.7rem;
            color: #FFF;
            text-transform: uppercase;
        }

        &-num {
            margin-right: 1.5rem;
        }

        & hr {
            background-color: #FFF;
            width: 25%;
            margin-right: 0;
            position: absolute;
            top: .7rem;
            right: 0;

            display: none;

            @media only screen and (max-width: 1150px) {
                right: -25%;
      
               }
        }


        &-active {

            & span {
                font-family: 'circeBold' !important;
                font-size: 1.4rem !important;
                line-height: 2rem !important;
                color: #FFF;
            }

            & hr {
                display: block !important;

                @media only screen and (max-width: 600px) {
                   display: none !important;
          
                   }
            }
        }
    }
}