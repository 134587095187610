.btn {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
     & svg {
        fill: #eb3f57;
     }

    &-white {
        & p {
            color: #FFF;
        }

        & svg {
            fill: #FFF;
         }
    }
}