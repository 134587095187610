.heading__wrapper {
    position: relative;
    padding-bottom: 1.3rem;

    &-white {

        & p span{
            color: #FFF !important;
        }

        &:after {
            content: "";
            height: .7rem;
            width: 4.5rem;
            background-color: #fff !important; 
            position: absolute;
            bottom: 0;
            left: .5rem;
        }

    }

    &:after {
        content: "";
        height: .7rem;
        width: 4.5rem;
        background-color: var(--color-primary);
        position: absolute;
        bottom: 0;
        left: .5rem;
    }
}