.clientMap {
    grid-column: full-start / full-end;

    display: flex;
    align-items: center;
    margin-bottom: 10.5rem;
    
    @media only screen and (max-width: 690px) {
        flex-direction: column;
        align-items: flex-start;

     }

    &__text {
        width: 25%;

        @media only screen and (max-width: 690px) {
            width: 100%;
            text-align: left;

    
         }
        &-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 690px) {
                align-items: flex-start;

    
        
             }
        }
    }

    &__names {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;

        @media only screen and (max-width: 690px) {
            margin-left: 3.2rem;
         }
        
    }

    &-name {

        &-1 {
            position: relative;
            padding-left: 3rem;

            &:after {
                content: "";
                height: 1.2rem;
                width: 1.2rem;
                background-color: #EB3F57;
                position: absolute;
                left: 0;
                vertical-align: center;
                border-radius: 50%;
                top:50%;
                transform:translate(0,-50%);
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                -webkit-transform:translate(0,-50%);
                -moz-transform:translate(0,-50%);
                -ms-transform:translate(0,-50%);
                -o-transform:translate(0,-50%);

                box-shadow: 2px 4px 4px rgba(#C04545, .16);
            }
        }

        &-2 {
            position: relative;
            padding-left: 3rem;

            &:after {
                content: "";
                height: 1.2rem;
                width: 1.2rem;
                background-color: #4FE2D8;
                position: absolute;
                left: 0;
                vertical-align: center;
                border-radius: 50%;
                top:50%;
                transform:translate(0,-50%);
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                -webkit-transform:translate(0,-50%);
                -moz-transform:translate(0,-50%);
                -ms-transform:translate(0,-50%);
                -o-transform:translate(0,-50%);

                box-shadow: 2px 4px 4px rgba(#C04545, .16);
            }
        }

        &-3 {
            position: relative;
            padding-left: 3rem;

            &:after {
                content: "";
                height: 1.2rem;
                width: 1.2rem;
                background-color: #F3E318;
                position: absolute;
                left: 0;
                vertical-align: center;
                border-radius: 50%;
                top:50%;
                transform:translate(0,-50%);
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                -webkit-transform:translate(0,-50%);
                -moz-transform:translate(0,-50%);
                -ms-transform:translate(0,-50%);
                -o-transform:translate(0,-50%);

                box-shadow: 2px 4px 4px rgba(#C04545, .16);
            }
        }

        &-4 {
            position: relative;
            padding-left: 3rem;

            &:after {
                content: "";
                height: 1.2rem;
                width: 1.2rem;
                background-color: #B65252;
                position: absolute;
                left: 0;
                vertical-align: center;
                border-radius: 50%;
                top:50%;
                transform:translate(0,-50%);
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                -webkit-transform:translate(0,-50%);
                -moz-transform:translate(0,-50%);
                -ms-transform:translate(0,-50%);
                -o-transform:translate(0,-50%);

                box-shadow: 2px 4px 4px rgba(#C04545, .16);
            }
        }

        &-5 {
            position: relative;
            padding-left: 3rem;

            &:after {
                content: "";
                height: 1.2rem;
                width: 1.2rem;
                background-color: #18B7F3;
                position: absolute;
                left: 0;
                vertical-align: center;
                border-radius: 50%;
                top:50%;
                transform:translate(0,-50%);
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                -webkit-transform:translate(0,-50%);
                -moz-transform:translate(0,-50%);
                -ms-transform:translate(0,-50%);
                -o-transform:translate(0,-50%);

                box-shadow: 2px 4px 4px rgba(#C04545, .16);
            }
        }
    }

    &__map {
        margin-left: auto;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        @media only screen and (max-width: 820px) {
            width: 80%;

         }

         @media only screen and (max-width: 690px) {
            width: 100%;

    
         }

        &-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__pin {

        &-container {
            position: relative;
            // width: 30rem;
            // height: 16.5rem;

          
        }

        &-details {
            background-color: #464554;
            padding: 2.5rem 3rem;
            position: absolute;
            top: -158px;
            left: 5px;
            border-radius: 1px;
            -webkit-border-radius: 1px;
            -moz-border-radius: 1px;
            -ms-border-radius: 1px;
            -o-border-radius: 1px;
            box-shadow: 2px 4px 4px rgba(var(--color-black), .16);
            z-index: 5;
            // min-width: max-content;
            height: max-content;
            width: 100%;
            display: none;
            opacity: 0;
            @media only screen and (max-width: 1120px) {
                display: none;
             }
            &--active {
                display: block;
                opacity: 1;

                @media only screen and (max-width: 1120px) {
                    display: none;
                 }
            }
        }
    }

}

#pinNep {
    // position: absolute;
    // top: 50%;
    top: -65%;
    right: -70%;
    position: relative;
    &::after {
        cursor: pointer;
        content: "";
        height: 1.2rem;
        width: 1.2rem;
        background-color: #4FE2D8;
        position: absolute;
        // left: 0;
        vertical-align: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
       z-index: 3;

        box-shadow: 2px 4px 4px rgba(#C04545, .16);

    }
}

#pinInd {
    // position: absolute;
    // top: 50%;
    top: -58%;
    right: -68%;
    position: relative;
    &::after {
        cursor: pointer;
        content: "";
        height: 1.2rem;
        width: 1.2rem;
        background-color: #F3E318;
        position: absolute;
        // left: 0;
        vertical-align: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
       

        box-shadow: 2px 4px 4px rgba(#C04545, .16);

    }
}

#pinInd2 {
    // position: absolute;
    // top: 50%;
    top: -53%;
    right: -41%;
    position: relative;
    &::after {
        cursor: pointer;
        content: "";
        height: 1.2rem;
        width: 1.2rem;
        background-color: #F3E318;
        position: absolute;
        // left: 0;
        vertical-align: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
       

        box-shadow: 2px 4px 4px rgba(#C04545, .16);

    }
}


#pinHk {
    // position: absolute;
    // top: 50%;
    top: -63%;
    right: -77%;
    position: relative;
    &::after {
        cursor: pointer;
        content: "";
        height: 1.2rem;
        width: 1.2rem;
        background-color: #B65252;

        position: absolute;
        // left: 0;
        vertical-align: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
       

        box-shadow: 2px 4px 4px rgba(#C04545, .16);

    }
}

#pinHk2 {
    // position: absolute;
    // top: 50%;
    top: -60%;
    right: -58%;
    position: relative;
    &::after {
        cursor: pointer;
        content: "";
        height: 1.2rem;
        width: 1.2rem;
        background-color: #B65252;

        position: absolute;
        // left: 0;
        vertical-align: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
       

        box-shadow: 2px 4px 4px rgba(#C04545, .16);

    }
}

#pinHk3 {
    // position: absolute;
    // top: 50%;
    top: -69%;
    right: -18%;
    position: relative;
    &::after {
        cursor: pointer;
        content: "";
        height: 1.2rem;
        width: 1.2rem;
        background-color: #18B7F3;

        position: absolute;
        // left: 0;
        vertical-align: center;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
       

        box-shadow: 2px 4px 4px rgba(#C04545, .16);

    }
}
