.contact {
    grid-column: leftgutter-start / rightgutter-end;

    display: grid;
    grid-template-columns: [leftgutter-start] minmax(3.6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.6rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;

    padding-top: 10rem;
    padding-bottom: 23rem;

    background-image: url(../../assets/img/bg2.png), url(../../assets/img/bg3.png);
    background-position: 105% 55%, -1% 94%;
    background-repeat: no-repeat;

    &__container {
        grid-column: full-start / full-end;

    }

    &__locations {
        display: flex;
        column-gap: 11rem;

        justify-content: flex-end;

        @media only screen and (max-width: 600px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 3.5rem;
         }

         @media only screen and (max-width: 400px) {
            grid-template-columns: 1fr ;
         }
    }


}