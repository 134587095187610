// @font-face {
//     font-family: 'poppinsRegular';
//     src: url('../../assets/font/Poppins-Regular.ttf') format('truetype');
// }

@font-face {
    font-family: 'circeBold';
    src: url('../../assets/fonts/Circe\ Bold.otf') format('truetype');
}

@font-face {
    font-family: 'circe';
    src: url('../../assets/fonts/Circe.otf') format('truetype');
}

@font-face {
    font-family: 'circeExtraLight';
    src: url('../../assets/fonts/Circe\ Extra\ Light.otf') format('truetype');
}

@font-face {
    font-family: 'circeLight';
    src: url('../../assets/fonts/Circe\ Light.otf') format('truetype');
}

@font-face {
    font-family: 'engravers';
    src: url('../../assets/fonts/Engravers.ttf') format('truetype');
}

body {
    font-family: 'circe', sans-serif;
}

.heading-large {
    font-family: 'circeBold';
    font-size: 4.6rem;
    line-height: 6.2rem;
    color: var(--color-black);
    text-transform: uppercase;

    @media only screen and (max-width: 1000px) {
        font-size: 4rem;


     }
}

.heading-primary {
    font-family: 'circeExtraLight';
    font-size: 3.5rem;
    line-height: 2.4rem;
    color: var(--color-black);
}

.heading-primary-bold {
    font-family: 'circeBold';
    font-size: 3.5rem;
    line-height: 2.4rem;
    color: var(--color-black);
}

.heading-secondary {
    font-family: 'circeBold';
    font-size: 1.8rem;
    line-height: 6.4rem;
    color: #777777;
    text-transform: uppercase;
}



.paragraph {

    &-light {
        font-family: 'circeLight';
        font-size: 1.3rem;
        line-height: 2.4rem;
        color: #82809D;
    }

    &-extraLight {
        font-family: 'circeExtraLight';
        font-size: 1.4rem;
        line-height: 2.3rem;
        color: #82809D;
    }

    &-extraLight--white {
        font-family: 'circeExtraLight';
        font-size: 1.4rem;
        line-height: 2.3rem;
        color: #FFF;
    }

    &-color {
        font-family: 'circeBold';
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: var(--color-primary);

        & svg {
            vertical-align: middle;
            display: inline-block;
            margin-left: .5rem;
        }
    }

    &-bold {
        font-family: 'circeBold';
        font-size: 1.4rem;
        line-height: 2rem;
        color: #FFF;
    }

    &-medium {
        font-family: 'circeLight';
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: #82809D;
    }
}

.navText {

    &-regular {
        font-family: 'circe';
        font-size: 1.2rem;
        line-height: 1.7rem;
        color: #24232E;
    }

    &-light {
        font-family: 'circeExtraLight';
        font-size: 1.2rem;
        line-height: 1.7rem;
        color: #82809D;
    }
}

.breadcrumbs__link {
    &,
    &:link,
    &:visited {
    font-family: 'circe';
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    }

    &:active,
    &:focus {
        outline: none;
    }
    &-active {
        font-family: 'circeBold';
        cursor:default ;

    }
}

.teammate {

    &-name {
        font-family: 'circe';
        font-size: 1.6rem;
        line-height: 6.4rem;
        color: #24232E;
        text-transform: uppercase;
    }

    &-desig {
        font-family: 'circeExtraLight';
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: #82809D;
    }
    
}

.services {

    &-heading {
        font-family: 'circe';
        font-size: 1.6rem;
        line-height: 2.3rem;
        color: #515067;
        text-transform: uppercase;
    }
}