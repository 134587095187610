.dynamicDesc {
    grid-column: full-start / full-end;
    margin-top: 19.5rem;
    display: flex;
    column-gap: 3.7rem;


    &__tabs {
        height: max-content;

        display: flex;
        flex-direction: column;

        padding: 2.7rem 2rem 2.7rem 0;
        box-shadow: 2px 6px 15px rgba(#D1D1D1, .3);
        background-color: #FFF;
        width: 100%;
    }

    

    &__btn {
        border: none;
        background-color: #FFFFFF;
        font-family: 'circeExtraLight';
        font-size: 1.5rem;
        line-height: 2.4rem;
        color: var(--color-gray);
        outline: none;
        border-left: .3rem solid transparent;
        padding-left: 1.5rem;
        cursor: pointer;

        transition: font-family .3s ease-in;
        
        &:hover {
            font-family: 'circeBold';

        }

        &-active {
            font-family: 'circeBold';
            border-left: .3rem solid var(--color-primary);
        }

        &-container {
            width: 23.9rem;

        }

        &-wrapper:not(:last-child) {
            margin-bottom: 2rem;

        }
    }

    &__content {
        @media only screen and (max-width: 600px) {
            display: none;
         }
        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 3.5rem;
            row-gap: 2rem;
            width: 80%;

            @media only screen and (max-width: 800px) {
                width: 100%;
             }

            
        }
    }
}

