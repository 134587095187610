*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

//RESPONSIVE BREAKPOINTS
$bp-giant: 93.75em;//1500px

$bp-huge: 81.25em;
$bp-largest: 75em; //1200px
$bp-semiLarge: 68.75em; //1100px

$bp-large: 62.5em; //1000px
$bp-medium: 56.25em; //900px
$bp-small: 43.75em; //700px
$bp-tiny: 31.25em; //500px

html {
    box-sizing: border-box;
    font-size: 62.5%; //10px/16px = 62.5% (1rem = 10px)
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;

    //     ::-moz-selection { /* Code for Firefox */
    //     background: #FFD67C;
    //   }
      
    //   ::selection {
    //     background: #FFD67C;
    //   }

    @media only screen and (max-width: $bp-medium) {
        font-size: 50%;
    }
}

// .container {
//     z-index: 1500;
//     transform: translateY(0);
//     position: relative;
// }

.page {
    display: grid;
    // grid-template-rows: 100vh repeat(7, min-content);
    grid-template-columns: [leftgutter-start] minmax(3.6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.6rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;
    // height: 100vh;
    // width: 100%;
    // background-color: #fff;
    background-image: url(../../assets/img/bg1.png), url(../../assets/img/bg3.png), url(../../assets/img/bg2.png);
    background-position: 106% 1%, -1% 27%, -8% 50%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 700px) {
        background-position: 276% 1%, -1% 32%, -8% 65%;
        }
}

.pages {
    display: grid;
    // grid-template-rows: 100vh repeat(7, min-content);
    grid-template-columns: [leftgutter-start] minmax(3.6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.6rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;
    
}

// .no-overflow {
//     overflow: hidden;
// }