.servicesPage {
    grid-column: leftgutter-start / rightgutter-end;

    display: grid;
    grid-template-columns: [leftgutter-start] minmax(3.6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.6rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;

    padding-top: 10rem;
    padding-bottom: 19.5rem;

    background-image: url(../../assets/img/bg2.png), url(../../assets/img/bg3.png);
    background-position: 105% -115%, -1% 78%;
    background-repeat: no-repeat;

    &__container {
        grid-column: full-start / full-end;
    }

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__wrapper {
        margin-top: 7.5rem;
    }

    &__content {

        background-color: #FFFFFF;

        & > p {
            display: none;
        }

        &-active {
            background-color: transparent !important;
            transition: all .2s ease-in;

            
            & .servicesPage__content-heading {
                background-color: #515067 !important;
            }

            & .servicesPage__content-heading p {
                color: #fff !important;
            }

            & > p {
                width: 89%;
                margin-left: auto;
                margin-top: 4.5rem;
                display: block;

                // animation-name: moveInUp;
                // animation-duration: .2s;
                // animation-timing-function: ease-in;
            }
        }

        &:not(:last-child){
            margin-bottom: 3rem;
        }

        &-heading {
            padding: 3rem 3.85rem 2.95rem 3.85rem;
            border: 1px solid #ECECEC;
           
            display: flex;
            align-items: center;
            cursor: pointer;
            & svg {
                margin-right: 5.3rem;
            }
        }
    }
}