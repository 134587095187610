@keyframes fadeIn {
    0% {
        opacity: 0;
    }

  
    100% {
        opacity: 1;
    }
}

@keyframes moveInUp {
    0% {
        opacity: 0;
        transform: translateY(-10rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);

}

    100% {
        opacity: 1;
        transform: translate(0);
    }
}