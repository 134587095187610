.logistic {
    grid-column: full-start / full-end;
    padding-top: 10rem;
    padding-bottom: 19.5rem;

    display: flex;
    column-gap: 3.7rem;


    &__content {
        width: 100%;
        height: max-content;
        &-text {
            width: 64%;
            height: 100%;
            padding: 7.2rem 5.4rem 8.3rem 5.5rem;
            position: absolute;
            top: 3rem;

            box-shadow: 1px 4px 10px rgba($color: #C04545, $alpha: .35);

            @media only screen and (max-width: 1200px) {
                height: max-content;
             }

             @media only screen and (max-width: 1000px) {
                padding: 5.2rem 3.4rem 6.3rem 3.5rem;
                width: 70%;

             }

             @media only screen and (max-width: 500px) {
                padding: 3.2rem 1.4rem 4.3rem 1.5rem;
                width: 75%;


             }

             @media only screen and (max-width: 400px) {
                width: 80%;

             }

            &-1 {
                background-color: #EB3F57;
                left: 0;
            }

            &-2 {
                background-color: #515067;
                right: 0;

                & + .logistic__content-imgBox {
                    text-align: left !important;
                }
            }

            &--wrapper {
                display: flex;

                &-1 {
                    & hr {
                        width: 26%;
                        height: 1.5px;
                        position: absolute;
                        right: -5%;
                        margin-top: .8rem;
                        background-color: #fff;
                    }
                }

                &-2 {

                    & hr {
                        width: 26%;
                        height: 1.5px;
                        position: absolute;
                        left: -20%;
                        margin-top: .8rem;
                        background-color: #fff;
                    }
                }
            }

        }

        &-wrapper {
            position: relative;
            width: 100%;
            height: 48.8rem;

            &:not(:last-child){
                margin-bottom: 14rem;
            }

        }

        &-imgBox {
            text-align: right;
            width: 100%;
            height: 100%;

        }

        &-img {
            width: 64%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__paraLight {
        @media only screen and (max-width: 1200px) {
            font-size: 1.2rem;
         }
    }

    &__btnTabs {
        @media only screen and (max-width: 800px) {
            display: none !important;

         }
    }

}