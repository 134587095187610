.faq {
    grid-column: leftgutter-start / rightgutter-end;

    display: grid;
    grid-template-columns: [leftgutter-start] minmax(3.6rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(3.6rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    position: relative;

    padding-top: 10rem;
    padding-bottom: 10rem;

    &__container {
        grid-column: full-start / full-end;
    }
}