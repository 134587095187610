.margin-bottom-medium {
    margin-bottom: 5.5rem !important;
}

.margin-bottom-avg {
    margin-bottom: 4.5rem !important;
}

.margin-bottom-bAvg {
    margin-bottom: 3rem !important;
}

.margin-bottom-small {
    margin-bottom: 3.7rem !important;
}

.margin-bottom-large {
    margin-bottom: 8.5rem !important;
}

.center {
    display: flex;
    justify-content: center;
}

.visible {
    display: block !important;

    animation-name: moveInUp;
    animation-duration: .5s;
}